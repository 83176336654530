/* eslint-disable */
export default {
    beforeUnmount() {
        window.vergeApp.dispose()
    },
    methods:{
        initFunction(){
            var params = v3d.AppUtils.getPageParams();
            var PUZZLES_DIR = `./vendors/s3d/puzzles/`;
            var logicURL = `./assets/stand/visual_logic.js`;
            var sceneURL = `./vendors/s3d/3DSTAND.gltf`;

            let vue = this;

            if (!sceneURL) {
                //console.log('No scene URL specified');
                return;
            }

            // some puzzles can benefit from cache
            v3d.Cache.enabled = true;

            if (v3d.AppUtils.isXML(logicURL)) {
                var logicURLJS = logicURL.match(/(.*)\.xml$/)[1] + '.js';
                new v3d.PuzzlesLoader().loadEditorWithLogic(PUZZLES_DIR, logicURLJS,
                    function() {
                        var initOptions = v3d.PL ? v3d.PL.execInitPuzzles({
                                container: vue.stand.container }).initOptions
                                : { useFullscreen: true };
                        var appInstance = vue.loadScene(sceneURL, initOptions);
                        v3d.PE.viewportUseAppInstance(appInstance);
                    }
                );
            } else if (v3d.AppUtils.isJS(logicURL)) {
                new v3d.PuzzlesLoader().loadLogic(logicURL, function() {
                    var initOptions = v3d.PL ? v3d.PL.execInitPuzzles({
                            container: vue.stand.container }).initOptions
                            : { useFullscreen: true };
                    vue.loadScene(sceneURL, initOptions);
                });
            } else {
                this.loadScene(sceneURL, { useFullscreen: true });
            }
        },
        loadScene(sceneURL, initOptions){
            initOptions = initOptions || {};
            let vue = this;

            var ctxSettings = {};
            if (initOptions.useBkgTransp) ctxSettings.alpha = true;
            if (initOptions.preserveDrawBuf) ctxSettings.preserveDrawingBuffer = true;

            var preloader = initOptions.useCustomPreloader
                    ? this.createCustomPreloader(initOptions.preloaderProgressCb,
                    initOptions.preloaderEndCb)
                    : new v3d.SimplePreloader({ container: this.stand.container });

            if (v3d.PE) {
                this.puzzlesEditorPreparePreloader(preloader);
            }

            window.vergeApp = new v3d.App(this.stand.container, ctxSettings, preloader);
            if (initOptions.useBkgTransp) {
                window.vergeApp.clearBkgOnLoad = true;
                window.vergeApp.renderer.setClearColor(0x000000, 0);
            }

            // namespace for communicating with code generated by Puzzles
            window.vergeApp.ExternalInterface = {};
            this.prepareExternalInterface(window.vergeApp);

            if (initOptions.preloaderStartCb) initOptions.preloaderStartCb();
            if (initOptions.useFullscreen) {
                this.initFullScreen();
            } else {
                var fsButton = document.getElementById('fullscreen_button');
                if (fsButton) fsButton.style.display = 'none';
            }

            sceneURL = initOptions.useCompAssets ? sceneURL + '.xz' : sceneURL; // sceneURL comes from REST api (file contains another domain (REST API's domain))
            window.vergeApp.loadScene(sceneURL, function() {
                window.vergeApp.enableControls();
                window.vergeApp.run();

                if (v3d.PE) v3d.PE.updateAppInstance(window.vergeApp);
                if (v3d.PL) v3d.PL.init(window.vergeApp, initOptions);

                vue.runCode(window.vergeApp);
            }, null, function() {
                //console.log('Can\'t load the scene ' + sceneURL);
            });

            return window.vergeApp;
        },
        createCustomPreloader(updateCb, finishCb){
            function CustomPreloader() {
                v3d.Preloader.call(this);
            }
        
            CustomPreloader.prototype = Object.assign(Object.create(v3d.Preloader.prototype), {
                onUpdate: function(percentage) {
                    v3d.Preloader.prototype.onUpdate.call(this, percentage);
                    if (updateCb) updateCb(percentage);
                },
                onFinish: function() {
                    v3d.Preloader.prototype.onFinish.call(this);
                    if (finishCb) finishCb();
                }
            });
        
            return new CustomPreloader();
        },
        puzzlesEditorPreparePreloader(preloader){
            if (v3d.PE.loadingUpdateCb !== undefined && v3d.PE.loadingFinishCb !== undefined) {
                var _onUpdate = preloader.onUpdate.bind(preloader);
                preloader.onUpdate = function(percentage) {
                    _onUpdate(percentage);
                    v3d.PE.loadingUpdateCb(percentage);
                }

                var _onFinish = preloader.onFinish.bind(preloader);
                preloader.onFinish = function() {
                    _onFinish();
                    v3d.PE.loadingFinishCb();
                }
            }
        },
        initFullScreen(){
            var fsButton = document.getElementById('fullscreen_button');
            if (!fsButton) return;
        
            var container = document.getElementById(this.stand.container);
        
            if (document.fullscreenEnabled ||
                    document.webkitFullscreenEnabled ||
                    document.mozFullScreenEnabled ||
                    document.msFullscreenEnabled)
                fsButton.style.display = 'inline';
        
            fsButton.addEventListener('click', function(event) {
                event.stopPropagation();
                if (document.fullscreenElement ||
                        document.webkitFullscreenElement ||
                        document.mozFullScreenElement ||
                        document.msFullscreenElement) {
                    exitFullscreen();
                } else
                    requestFullscreen(container);
            });
        
            function changeFullscreen() {
                if (document.fullscreenElement ||
                        document.webkitFullscreenElement ||
                        document.mozFullScreenElement ||
                        document.msFullscreenElement) {
                    fsButton.classList.remove('fullscreen-open');
                    fsButton.classList.add('fullscreen-close');
                } else {
                    fsButton.classList.remove('fullscreen-close');
                    fsButton.classList.add('fullscreen-open');
                }
            }
        
            document.addEventListener('webkitfullscreenchange', changeFullscreen);
            document.addEventListener('mozfullscreenchange', changeFullscreen);
            document.addEventListener('msfullscreenchange', changeFullscreen);
            document.addEventListener('fullscreenchange', changeFullscreen);
        
            function requestFullscreen(elem) {
                if (elem.requestFullscreen)
                    elem.requestFullscreen();
                else if (elem.mozRequestFullScreen)
                    elem.mozRequestFullScreen();
                else if (elem.webkitRequestFullscreen)
                    elem.webkitRequestFullscreen();
                else if (elem.msRequestFullscreen)
                    elem.msRequestFullscreen();
            }
        
            function exitFullscreen() {
                if (document.exitFullscreen)
                    document.exitFullscreen();
                else if (document.mozCancelFullScreen)
                    document.mozCancelFullScreen();
                else if (document.webkitExitFullscreen)
                    document.webkitExitFullscreen();
                else if (document.msExitFullscreen)
                    document.msExitFullscreen();
            }
        },
        prepareExternalInterface(app){
        },
        runCode(app){
            v3d.puzzles.procedures['initStand'](this.stand.current);
        }
    }
}
/* eslint-enable */